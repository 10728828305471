<template>
  <div class="header-wrapper" id="header-wrapper"
       :class="[{hide: $route.name === 'quizHub' || $route.name === 'quizGame'|| $route.name === 'stage1'|| $route.name === 'stage2'}]">

    <div class="content-header">
      <a
        :onclick="`gtag('event', 'click', {  'event_category': ' клин на логотип',  'event_label': '${$route.name}'})`"
        @click="reload" class="logo">
        <svg viewBox="0 0 104 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M96.8807 21.7853C96.8807 25.5505 93.9963 29.2496 89.5927 29.2496C85.3651 29.2496 82.3046 26.0349 82.3046 21.7853C82.3046 17.0954 85.8055 14.2991 89.5046 14.2991C94.1064 14.2991 96.8807 18.0202 96.8807 21.7853ZM96.3303 8.5303V10.0496C94.1064 8.26608 91.3541 7.34131 88.3596 7.34131C80.7633 7.34131 74.8184 13.6826 74.8184 21.7853C74.8184 25.3964 76.0734 28.8532 78.3413 31.4954C80.8514 34.4239 84.7486 36.1853 88.756 36.1853C91.6404 36.1853 94.1725 35.2826 96.3303 33.4771V34.9964C96.3303 35.3486 96.6165 35.6129 96.9468 35.6129H102.936C103.288 35.6129 103.552 35.3266 103.552 34.9964V8.5303C103.552 8.17801 103.266 7.91379 102.936 7.91379H96.9468C96.6165 7.91379 96.3303 8.17801 96.3303 8.5303Z"
            fill="#005A85"/>
          <path
            d="M17.1083 29.4479C15.4349 29.4479 14.2239 28.9415 13.4312 27.9287C12.8147 27.18 12.5505 26.1672 12.5505 24.5158V14.2993H20.2569C20.5872 14.2993 20.8514 14.0351 20.8514 13.7048V8.44243C20.8514 8.11215 20.5872 7.84793 20.2569 7.84793H12.5284V0.802061C12.5284 0.449767 12.2422 0.185547 11.9119 0.185547H5.6367C5.2844 0.185547 5.02018 0.471785 5.02018 0.802061V7.84793H0.616514C0.26422 7.84793 0 8.13417 0 8.46445V13.6608C0 14.0131 0.286239 14.2773 0.616514 14.2773H5.02018V23.8333C5.02018 27.7305 5.6367 30.2186 7.06789 32.1122C9.02752 34.7323 12.3303 36.1195 16.6459 36.1195C19.9706 36.1195 22.0844 35.3268 23.2294 34.4241C23.5596 34.1599 23.6257 33.5874 23.4275 33.3011C23.2734 33.081 20.7193 29.0076 20.7193 29.0076C20.4771 28.6333 19.9927 28.5452 19.5743 28.7654C19.0239 29.1177 17.945 29.4479 17.1083 29.4479Z"
            fill="#005A85"/>
          <path
            d="M35.3614 13.3964C37.7173 13.3964 40.4036 15.1799 40.7338 18.0864H29.5485C29.9008 15.8185 32.1907 13.3964 35.3614 13.3964ZM35.3614 7.29736C31.5082 7.29736 28.0293 8.83865 25.5632 11.635C23.2072 14.2992 21.9302 17.8882 21.9302 21.7414C21.9302 30.2185 27.8751 36.1414 36.3962 36.1414C40.1393 36.1414 43.8164 34.7763 46.4146 32.5084C46.5247 32.3983 46.6568 32.2662 46.8109 32.1341C47.0311 31.9139 47.0531 31.5616 46.877 31.3194C46.2164 30.4386 44.6531 28.3909 43.9045 27.4001C43.6843 27.1139 43.2439 27.0698 42.9797 27.3341C42.8036 27.4882 42.6054 27.6864 42.3852 27.8625C41.1522 28.8533 39.1705 30.0643 36.5504 30.0643C32.6311 30.0643 29.6366 27.4442 29.1962 23.657H47.4494C47.9118 23.657 48.2861 23.3267 48.3302 22.8643C48.3742 22.512 48.3962 22.0497 48.3962 21.4772C48.3962 17.69 47.0971 14.1451 44.7412 11.5029C42.3192 8.77259 38.9944 7.29736 35.3614 7.29736Z"
            fill="#005A85"/>
          <path
            d="M62.0259 29.0517L62.0479 25.0664C62.0479 23.437 62.3782 21.6315 63.0167 19.782C63.7213 17.6682 64.7562 15.8848 65.8791 14.5857L67.5965 12.3618V21.6756L62.0259 29.0517Z"
            fill="#35786E"/>
          <path
            d="M67.5962 12.3395L71.2512 7.6056C71.5375 7.20927 72.0219 7.07716 72.4622 7.25331C72.9026 7.42946 73.1888 7.86982 73.1888 8.37625L73.1668 14.2551L67.5962 21.6753V12.3395Z"
            fill="#B5D16C"/>
          <path
            d="M73.1668 14.2554L73.1448 17.6462C73.2109 19.3856 72.9246 21.3893 72.22 23.415C71.6035 25.2645 70.7888 26.8939 69.82 28.149L67.5962 31.0334V21.6535L73.1668 14.2554Z"
            fill="#317575"/>
          <path
            d="M67.5965 21.6533V31.0111L63.9635 35.723C63.6773 36.1194 63.1708 36.2515 62.7525 36.0753C62.3121 35.8992 62.0259 35.4588 62.0259 34.9524L62.0479 29.0074L67.5965 21.6533Z"
            fill="#005A85"/>
          <path
            d="M61.2552 29.0517L61.2332 25.0664C61.2332 23.437 60.9029 21.6315 60.2644 19.782C59.5598 17.6682 58.5249 15.8848 57.402 14.5857L55.6846 12.3618V21.6756L61.2552 29.0517Z"
            fill="#35786E"/>
          <path
            d="M55.6845 12.3395L52.0294 7.6056C51.7432 7.20927 51.2588 7.07716 50.8184 7.25331C50.378 7.42946 50.0918 7.86982 50.0918 8.37625L50.1138 14.2551L55.6845 21.6533V12.3395Z"
            fill="#B5D16C"/>
          <path
            d="M50.1138 14.2554L50.1358 17.6462C50.0697 19.3856 50.356 21.3893 51.0606 23.415C51.6771 25.2645 52.4918 26.8939 53.4606 28.149L55.7064 31.0554V21.6535L50.1138 14.2554Z"
            fill="#317575"/>
          <path
            d="M55.6846 21.6533V31.0111L59.3176 35.723C59.6038 36.1194 60.1102 36.2515 60.5286 36.0753C60.969 35.8992 61.2552 35.4588 61.2552 34.9524L61.2332 29.0074L55.6846 21.6533Z"
            fill="#005A85"/>
        </svg>
      </a>
      <div class="center">
        <router-link
          :onclick="`gtag('event', 'click', {  'event_category': ' кнопка Препарати',  'event_label': '${$route.name}'})`"
          class="item in-link hover_type2" to="/">Препарати
        </router-link>

        <router-link
          :class="[{ active: $route.path.includes('brochures') && !$route.path.includes('medicines-are-available')}]"
          :onclick="`gtag('event', 'click', {  'event_category': ' кнопка Брошури Тева', 'event_label': '${$route.name}'})`"
          class="item in-link hover_type2" to="/brochures">Брошури Тева
        </router-link>

        <router-link
          :onclick="`gtag('event', 'click', {  'event_category': ' кнопка Переваги Лiнiйки бренду Тева',  'event_label': '${$route.name}'})`"
          class="item in-link hover_type2" to="/answers">Переваги Лiнiйки бренду Тева
        </router-link>

        <a
          :class="[{ active: $route.path.includes('page') || $route.path.includes('useful-by-teva')|| $route.path.includes('clinical-case')}]"
          class="item in-link drop-link">Корисне

          <span class="drop-link-menu">
            <router-link :to="{ name: 'questionnaire' }">
              <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="7.53223" width="26" height="29.468" rx="4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 22.8828H20" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 28.8828V16.8828" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4263 5.25371C17.4876 4.99817 17.52 4.7317 17.52 4.45778C17.52 2.5481 15.9439 1 13.9998 1C12.0556 1 10.4796 2.5481 10.4796 4.45778C10.4796 4.7317 10.512 4.99817 10.5733 5.25371H7.51291C6.65603 5.25371 5.89435 5.79958 5.61899 6.61101L5.2163 7.79768C4.77615 9.0947 5.74054 10.4404 7.11021 10.4404H20.8899C22.2596 10.4404 23.224 9.0947 22.7838 7.79768L22.3811 6.61101C22.1057 5.79957 21.3441 5.25371 20.4872 5.25371H17.4263Z" fill="#488681"/>
                <path d="M17.4263 5.25371L16.9401 5.1371L16.7922 5.75371H17.4263V5.25371ZM10.5733 5.25371V5.75371H11.2074L11.0595 5.1371L10.5733 5.25371ZM5.61899 6.61101L5.14551 6.45033H5.14551L5.61899 6.61101ZM5.2163 7.79768L5.68978 7.95835L5.2163 7.79768ZM22.7838 7.79768L22.3103 7.95835L22.7838 7.79768ZM22.3811 6.61101L22.8546 6.45033V6.45033L22.3811 6.61101ZM17.02 4.45778C17.02 4.6921 16.9923 4.91944 16.9401 5.1371L17.9125 5.37032C17.9829 5.0769 18.02 4.77129 18.02 4.45778H17.02ZM13.9998 1.5C15.6763 1.5 17.02 2.83264 17.02 4.45778H18.02C18.02 2.26356 16.2116 0.5 13.9998 0.5V1.5ZM10.9796 4.45778C10.9796 2.83264 12.3233 1.5 13.9998 1.5V0.5C11.788 0.5 9.97958 2.26356 9.97958 4.45778H10.9796ZM11.0595 5.1371C11.0073 4.91944 10.9796 4.6921 10.9796 4.45778H9.97958C9.97958 4.7713 10.0167 5.07691 10.0871 5.37032L11.0595 5.1371ZM7.51291 5.75371H10.5733V4.75371H7.51291V5.75371ZM6.09247 6.77169C6.29899 6.16311 6.87025 5.75371 7.51291 5.75371V4.75371C6.44181 4.75371 5.48971 5.43604 5.14551 6.45033L6.09247 6.77169ZM5.68978 7.95835L6.09247 6.77169L5.14551 6.45033L4.74282 7.637L5.68978 7.95835ZM7.11021 9.94038C6.08296 9.94038 5.35967 8.93112 5.68978 7.95835L4.74282 7.637C4.19264 9.25828 5.39813 10.9404 7.11021 10.9404V9.94038ZM20.8899 9.94038H7.11021V10.9404H20.8899V9.94038ZM22.3103 7.95835C22.6404 8.93112 21.9171 9.94038 20.8899 9.94038V10.9404C22.602 10.9404 23.8075 9.25828 23.2573 7.637L22.3103 7.95835ZM21.9076 6.77168L22.3103 7.95835L23.2573 7.637L22.8546 6.45033L21.9076 6.77168ZM20.4872 5.75371C21.1299 5.75371 21.7011 6.16311 21.9076 6.77168L22.8546 6.45033C22.5104 5.43604 21.5583 4.75371 20.4872 4.75371V5.75371ZM17.4263 5.75371H20.4872V4.75371H17.4263V5.75371Z" fill="white"/>
              </svg>

              Анкета "Клінічні випадки"
            </router-link>

<!--            <router-link to="/clinical-case">-->
<!--              <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <rect x="1" y="1" width="26" height="32" rx="4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M6 8H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M6 14H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M6 20H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M6 26H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              Клінічні випадки-->
<!--            </router-link>-->

            <router-link to="/useful-by-teva">
              <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="26" height="32" rx="4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 8H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 14H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 20H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 26H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              Статті
            </router-link>
          </span>
        </a>

        <!-- TODO: add gtag -->
        <router-link class="item out-link hover_type2" to="/cardio-calc">
          Кардіо калькулятор
        </router-link>

        <router-link
          :onclick="`gtag('event', 'click', {  'event_category': ' кнопка Доступнi лiки',  'event_label': '${$route.name}'})`"
          class="item out-link hover_type2" to="/brochures/medicines-are-available">
          Доступнi лiки
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5415 4.32129L4.5415 12.3213" stroke="#35786E" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M5.875 4.32129H12.5417V10.988" stroke="#35786E" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
      <a
        :onclick="`gtag('event', 'click', {  'event_category': ' кнопка Ігри Тева',  'event_label': '${$route.name}'})`"
        @click="GamePopup()" class="game hover_type3">

        <span>Ігри Тева</span>

        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5415 4.32129L4.5415 12.3213" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M5.875 4.32129H12.5417V10.988" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </a>

      <div
        :onclick="`gtag('event', 'click', {  'event_category': ' открытие меню',  'event_label': '${$route.name}'})`"
        @click="doMenu" class="burger-btn">
        <svg v-if="!getMenu" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="2" rx="1" fill="#35786E"/>
          <rect y="8" width="24" height="2" rx="1" fill="#35786E"/>
          <rect y="16" width="24" height="2" rx="1" fill="#35786E"/>
        </svg>
        <svg v-if="getMenu" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1.60657" y="0.192383" width="24" height="2" rx="1" transform="rotate(45 1.60657 0.192383)"
                fill="#35786E"/>
          <rect y="16.9706" width="24" height="2" rx="1" transform="rotate(-45 0 16.9706)" fill="#35786E"/>
        </svg>

      </div>
    </div>

    <div class="drop-menu" v-if="getMenu">
      <div class="drop-menu-content">
        <router-link
          :onclick="`gtag('event', 'click', {  'event_category': ' Препарати',  'event_label': '${$route.name}'})`"
          class="item in-link hover_type2" to="/">Препарати
        </router-link>

        <router-link
          :class="[{ active: $route.path.includes('brochures') && !$route.path.includes('medicines-are-available')}]"
          :onclick="`gtag('event', 'click', {  'event_category': ' кнопка Брошури Тева', 'event_label': '${$route.name}'})`"
          class="item in-link hover_type2" to="/brochures">Брошури Тева
        </router-link>

        <router-link
          :onclick="`gtag('event', 'click', {  'event_category': ' Переваги Лiнiйки бренду Тева',  'event_label': '${$route.name}'})`"
          class="item in-link hover_type2" to="/answers">Переваги Лiнiйки бренду Тева
        </router-link>

        <a @click="mobDrop = !mobDrop" :class="[{ active: mobDrop}]"
           class="item in-link drop-link hover_type2">Корисне
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M15 8.71387L7.99982 1.71369L0.999644 8.71387" stroke="#3C7E79" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </a>

        <router-link v-if="mobDrop" class=" item in-link hover_type2" :to="{ name: 'questionnaire' }"
                     :class="[{ active: $route.path.includes('page') || $route.path.includes('questionnaire')} ]">
          Анкета "Клінічні випадки"
        </router-link>

<!--        <router-link v-if="mobDrop" class=" item in-link hover_type2" to="/clinical-case"-->
<!--                     :class="[{ active: $route.path.includes('page') || $route.path.includes('clinical-case')} ]">-->
<!--          Клінічні випадки-->
<!--        </router-link>-->

        <router-link v-if="mobDrop" class=" item in-link hover_type2" to="/useful-by-teva"
                     :class="[{ active: $route.path.includes('page') || $route.path.includes('useful-by-teva')} ]">
          Статті
        </router-link>

        <!-- TODO: add gtag -->
        <router-link to="/cardio-calc" :class="[{ active: $route.path.includes('cardio-calc')}]"
                     class=" item in-link hover_type2">
          Кардіо калькулятор
        </router-link>


        <router-link to="/brochures/medicines-are-available"
                     :onclick="`gtag('event', 'click', {  'event_category': ' Доступнi лiки',  'event_label': '${$route.name}'})`"
                     @click="doMenu()" class="item out-link hover_type2">
          Доступнi лiки
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5415 4.32129L4.5415 12.3213" stroke="#35786E" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M5.875 4.32129H12.5417V10.988" stroke="#35786E" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </router-link>
        <a
          :onclick="`gtag('event', 'click', {  'event_category': ' кнопка Ігри Тева',  'event_label': '${$route.name}'})`"
          @click="doMenu(); GamePopup();" class="game hover_type3">

          <span>Ігри Тева</span>

          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5415 4.32129L4.5415 12.3213" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M5.875 4.32129H12.5417V10.988" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </a>
      </div>
    </div>

    <gamePopup v-if="getGamePopup"></gamePopup>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import gamePopup from './popup-game-redirect.vue'
import router from "@/router";

export default {
  name: "header-wrapper",
  watch: {
    "$route.name"(to, from) {
      if (this.getMenu) {
        this.doMenu()
        this.mobDrop = false
      }
    }
  },

  components: {
    gamePopup
  },

  data: () => ({
    mobDrop: false,
  }),
  computed: {

    routeName() {
      return this.$route.name;
    },
    ...mapGetters([
      'getMenu',
      'getGamePopup'
    ])
  },
  methods: {
    ...mapActions([
      'doMenu',
      'GamePopup'
    ]),
    reload() {
      window.location = window.location.origin
    },
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.header-wrapper {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 100;
  background: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  &.hide {
    display: none;
  }

  @media screen and (max-width: 1065px) {
    height: 60px;
  }

  .content-header {
    max-width: 1270px;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    position: relative;
    z-index: 12;


    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
    background: $white;

    .logo {
      cursor: pointer;
      max-width: 104px;
      width: 100%;


      @media screen and (max-width: 1240px) {
        max-width: 87px;
      }

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media screen and (max-width: 1065px) {
        display: none;
      }

      .item {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-family: $R;
        color: $teal;
        height: 100%;
        padding: 0 5px;
        margin: 0 10px;

        text-decoration: none;
        position: relative;

        @media screen and (max-width: 1240px) {
          font-size: 14px;
          margin: 0 9px;
        }

        &.router-link-exact-active {
          font-family: $B;

          &:after {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;

            content: '';
            width: 100%;
            height: 3px;
            border-radius: 5px;
            background: $teal;
          }
        }

        &.router-link-active-custom {
          font-family: $B;

          &:after {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;

            content: '';
            width: 100%;
            height: 3px;
            border-radius: 5px;
            background: $teal;
          }
        }

        svg {
          max-width: 16px;
          width: 100%;

          height: auto;
          display: block;

        }

        &.active {
          font-family: $B;

          &:after {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;

            content: '';
            width: 100%;
            height: 3px;
            border-radius: 5px;
            background: $teal;
          }
        }

        &.drop-link {
          position: relative;
          cursor: pointer;

          &:before {
            opacity: 0;
            pointer-events: none;
            content: '';
            position: absolute;
            left: -5px;
            top: 50%;
            transform: translateY(-50%);

            width: 5px;
            height: 22px;
            background: #488681;

          }

          @media screen and (min-width: 1024px) {
            &:hover {
              font-weight: bold;

              &:after {
                opacity: 0 !important;
              }

              .drop-link-menu {

                opacity: 1;
                pointer-events: visible;
              }

              &:before {
                opacity: 1;
              }
            }
          }

          .drop-link-menu {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            left: 0;
            top: calc(100%);
            background: #488681;
            min-width: 230px;

            a {
              display: flex;
              align-items: center;
              padding: 17px 18px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.07);
              color: var(--ffffff, #FFF);
              font-family: $R;
              font-weight: 400;
              font-size: 16px;
              text-decoration: none;

              @media screen and (min-width: 1024px) {
                &:hover {
                  font-weight: bold;
                }

              }

              &:last-child {
                border: none;
              }

              svg {
                min-width: 26px;
                width: 26px;
                height: auto;
                display: block;
                margin-right: 16px;
              }
            }


          }
        }
      }
    }

    .game {
      cursor: pointer;

      min-width: 160px;
      min-height: 50px;

      background: $teal;
      border-radius: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 16px;
      font-family: $B;
      color: $white;

      text-decoration: none;


      @media screen and (max-width: 1240px) {
        min-width: 140px;
        min-height: 40px;
        font-size: 14px;
      }

      @media screen and (max-width: 1065px) {
        display: none;
      }

      span {
        font-size: 16px;
        font-family: $B;
        color: $white;

        position: relative;
        z-index: 2;

        @media screen and (max-width: 1240px) {
          font-size: 14px;
        }

      }

      svg {
        position: relative;
        z-index: 2;
        max-width: 16px;
        width: 100%;

        height: auto;
        display: block;

        @media screen and (max-width: 1240px) {
          max-width: 14px;
        }

      }
    }

    .burger-btn {
      display: none;
      cursor: pointer;

      @media screen and (max-width: 1065px) {
        display: block;
      }

      svg {
        max-width: 24px;
        width: 100%;
        height: auto;
        display: block;
      }
    }

  }

  .drop-menu {
    position: fixed;
    z-index: 10;
    right: 0;
    top: var(--height_header);
    bottom: 0;
    left: 0;
    min-height: calc(100vh);
    min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));

    background: white;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow-y: auto;
    padding: 0 0 20px 0;

    .drop-menu-content {
      max-width: 300px;
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 1065px) {
        display: none;
      }

      .item {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: $R;
        color: $teal;
        height: 100%;

        text-decoration: none;
        position: relative;

        margin: 20px auto;

        &.drop-link {


          &.active {
            font-weight: bold;

            svg {
              transform: rotate(180deg);
            }
          }

          svg {
            transition: transform .2s ease;
            width: 16px;
            height: auto;
            display: block;
            margin-left: 5px;

          }
        }


        &.router-link-exact-active {
          font-family: $B;
        }

        &.router-link-active-custom {
          font-family: $B;
        }

        &.active {
          font-family: $B;
        }

        svg {
          max-width: 16px;
          width: 100%;

          height: auto;
          display: block;

        }
      }

      .game {
        max-width: 160px;
        width: 100%;
        min-height: 50px;

        background: $teal;
        border-radius: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 16px;
        font-family: $B;
        color: $white;

        text-decoration: none;

        margin: 52px auto 0 auto;

        span {
          font-size: 16px;
          font-family: $B;
          color: $white;

          position: relative;
          z-index: 2;

          @media screen and (max-width: 1240px) {
            font-size: 14px;
          }

        }

        svg {
          position: relative;
          z-index: 2;
          max-width: 16px;
          width: 100%;

          height: auto;
          display: block;

          @media screen and (max-width: 1240px) {
            max-width: 14px;
          }

        }
      }
    }
  }
}
</style>